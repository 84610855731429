// Dependencies
import React, { Fragment } from "react";
import styled from "styled-components";
import Fade from "react-reveal";
import { graphql } from "gatsby";

// Components
import TitleImage from "../components/title-image";
import SEO from "../components/seo";
import DoubleSlider from "../components/doubleSlider";
import { Container } from "../pages/index";

interface Props {
  data: {
    allDataJson: {
      edges: {
        node: {};
      };
    };
  };
}

const DlaFirm: React.FC<Props> = ({ data }) => {
  const sections = data.allDataJson.edges[0].node.dlafirmPage.sections;
  return (
    <>
      <TitleImage
        image={data.allDataJson.edges[0].node.dlafirmPage.titleImage}
        title={"Dla Firm"}
      ></TitleImage>
      <Fade>
        <SEO />
        <Wrapper>
          <Container>
            {sections.map((section, index) => {
              return (
                <Fragment key={index}>
                  <DoubleSlider images={section.images} />
                  <StyledArticle>
                    <Heading>{section.heading}</Heading>
                    {section.content.map((c, index) => {
                      return <p key={index}>{c}</p>;
                    })}
                  </StyledArticle>
                </Fragment>
              );
            })}
          </Container>
        </Wrapper>
      </Fade>
    </>
  );
};

export default DlaFirm;

const Wrapper = styled.div`
  padding: ${props => props.theme.space[6]}px 0;
  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]}px 0;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    padding: ${props => props.theme.space[10]}px 0;
  }
`;

const Heading = styled.h2`
  font-size: ${props => props.theme.fontSizes[5]}px;
  text-transform: uppercase;
  margin: ${props => props.theme.space[6]}px 0
    ${props => props.theme.space[4]}px 0;
`;

const StyledArticle = styled.article`
  margin-bottom: ${props => props.theme.space[10]}px;
  padding-left: 10px;
  padding-right: 10px;

  p {
    margin-bottom: ${props => props.theme.space[3]}px;
  }
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          dlafirmPage {
            titleImage {
              childImageSharp {
                fluid(quality: 85, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sections {
              heading
              content
              images {
                childImageSharp {
                  fluid(quality: 85, maxWidth: 920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
